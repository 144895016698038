import { Component } from '@angular/core';
import { FormBuilder, FormGroup, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { UsersService } from 'src/app/users.service';
import { DatePipe } from '@angular/common'
@Component({
  selector: 'app-address',
  templateUrl: './address.component.html',
  styleUrls: ['./address.component.css'],
})
export class AddressComponent {
  suggetionsList: any = []
  autocompletePlaceHolder: string = "Search Address"
  servicePlaceholder:string = "Select Test"
  public keyword = 'description';
  public searchkeyword = 'name';
  addressForm: UntypedFormGroup;
  selectedList:any
  slots:any
  servicesList:any = []
  maxdate = new Date();
  constructor(private spinner: NgxSpinnerService,private fb: FormBuilder,private DatePipe: DatePipe,
    private userservice: UsersService, private route: Router, private router: ActivatedRoute, private formBuilder: UntypedFormBuilder) { }

 ngOnInit():void{

  this.addressForm = this.fb.group({
    address: ['', Validators.required],
    landmark: [''],
    state: ['', Validators.required],
    pincode: ['',[Validators.required, Validators.pattern(/^\d{6}$/)]],
    startDate: ['', Validators.required]
  });


 }
 
 lat:any
 long:any
  onSearch(event: any, type: any) {
    const searchwoard = {
      input: event
    }
    this.userservice.googleSearchAddress(searchwoard).subscribe((response: any) => {
      this.suggetionsList = response.data.prediction
      this.selectedList = response.data.location
      console.log("selected location",this.selectedList)
      this.addressForm.patchValue({
        landmark:this.selectedList.landmark,
        state: this.selectedList.state,
        pincode:this.selectedList.pincode, 
      })

      this.lat = this.selectedList.lat,
      this.long = this.selectedList.lng


      if (response.code == "S002") {
      } if (response.code == "ND01") {
        // this.route.navigate(['/Dashboard/Task/MyTask'])
      }
    }, (error) => {
      //  alert(error.error.message);
    })
  }

  onChangeSearch(event: any) {
    console.log("input change", event)
  }

  onFocused(event: any, type: any) {
    console.log("input onfocus", event)
    if(event == undefined){
      this.addressForm.reset()
      this.slots = []
    }
  }



  onSubmit() {
    console.log(this.addressForm.value);
  }

  getSlots(){

    console.log(this.addressForm.value);
    if(this.lat != null && this.lat !== '' &&
      this.long != null && this.long !== '' &&
      this.addressForm.value.startDate != null && this.addressForm.value.startDate !== ''){

      

      let params:any = {
        latitude : this.lat,
        longitude: this.long,
        date:this.DatePipe.transform(this.addressForm.value.startDate, 'dd-MM-yyyy')

      }
      
      this.userservice.redcliffeSlotsBooking(params).subscribe((response: any) => {
             
        console.log("slots",response.data)
        
         this.slots = response.data.results
         
  
        if (response.code == "S002") {
        } if (response.code == "ND01") {
          // this.route.navigate(['/Dashboard/Task/MyTask'])
        }
      }, (error) => {
        //  alert(error.error.message);
      })

    }else{
      alert('Please Select Date & Address')
    }

  }


  serviceSearch(event: any) {
    const searchwoard = {
      test: event
    }
    this.userservice.redcliffServiceSearch(searchwoard).subscribe((response: any) => {
  
        //  console.log("Test of Redclif",response)
         this.servicesList = response.data.data
         console.log("Test of Redclif", this.servicesList)
      if (response.code == "S002") {
      } if (response.code == "ND01") {
        // this.route.navigate(['/Dashboard/Task/MyTask'])
      }
    }, (error) => {
      //  alert(error.error.message);
    })
  }
  selectedServiceName:any
  addedServices:any = []
  serachedList(event:any){
    console.log("searched service List",event)
    this.selectedServiceName = event
  }

  addServiceToList() {

    if (this.selectedServiceName != '' && this.selectedServiceName != null) {
      const isDuplicate = this.addedServices.some((service:any) => service.id === this.selectedServiceName.id);
      if (!isDuplicate) {
        this.addedServices.push(this.selectedServiceName);
        this.selectedServiceName = '';
      } else {
        alert("Service already added");
      }
    } else {
      alert("Select Service")
    }

  }


  deleteSelectedService(id:any){
    this.addedServices = this.addedServices.filter((data:any)=>{
      if(data.id!=id){
         return data
      }
    })
  }

  // this below function for to get request details 
  viewRequest() {
    this.spinner.show();

    this.userservice.viewRequest("").subscribe(
      async (data: any) => {
        this.spinner.hide();
       
        
      },
      (error) => {
        this.spinner.hide();
        alert(error.error.data);
      },
    );
  }

}
