<div class="mainDiv">
    <form [formGroup]="addressForm" (ngSubmit)="onSubmit()" novalidate>
        <div class="card">
            <div class="ms-3 mt-4">
                <div class="row">

                    <!-- Address Field with Autocomplete -->
                    <div class="col-5 col-md-5 col-lg-5 ms-1 mb-2 mt-2">
                        <label for="address">Address <span class="text-danger">*</span></label>
                        <ng-autocomplete style="border-radius: 10px;" [data]="suggetionsList" name="Name"
                            formControlName="address" [placeholder]="autocompletePlaceHolder" [searchKeyword]="keyword"
                            notFoundText="Not found" (inputChanged)='onSearch($event, "pick")'
                            [itemTemplate]="itemTemplateStatic" (selected)='onChangeSearch($event)'
                            (inputCleared)='onFocused($event, "pick")' [notFoundTemplate]="notFoundTemplate">
                        </ng-autocomplete>
                        <ng-template #itemTemplateStatic let-item>
                            <a [innerHTML]="item.description"></a>
                        </ng-template>
                        <ng-template #notFoundTemplate let-notFound>
                            <div [innerHTML]="notFound"></div>
                        </ng-template>
                        <div *ngIf="addressForm.get('address')?.invalid && addressForm.get('address')?.touched"
                            class="text-danger">
                            Address is required.
                        </div>
                    </div>

                    <!-- Nearby Landmark Field -->
                    <div class="col-5 col-md-5 col-lg-5 ms-1 mb-2 mt-2">
                        <label for="landmark">Nearby Landmark</label>
                        <input class="form-control" id="landmark" formControlName="landmark"
                            placeholder="Nearby landmark" />
                    </div>

                    <!-- State Field -->
                    <div class="col-3 col-md-3 ms-1 mb-2 mt-2">
                        <label for="state">State <span class="text-danger">*</span></label>
                        <input class="form-control" id="state" formControlName="state" placeholder="State" />
                        <div *ngIf="addressForm.get('state')?.invalid && addressForm.get('state')?.touched"
                            class="text-danger">
                            State is required.
                        </div>
                    </div>

                    <!-- Pin Code Field -->
                    <div class="col-3 col-md-3 ms-1 mb-2 mt-2">
                        <label for="pincode">Pin Code <span class="text-danger">*</span></label>
                        <input class="form-control" id="pincode" formControlName="pincode" placeholder="Pin code" />
                        <div *ngIf="addressForm.get('pincode')?.invalid && addressForm.get('pincode')?.touched"
                            class="text-danger">
                            <div *ngIf="addressForm.get('pincode')?.errors?.['required']">Pin Code is required.</div>
                            <div *ngIf="addressForm.get('pincode')?.errors?.['pattern']">Pin Code must be a 6-digit
                                number.</div>
                        </div>
                    </div>

                    <!-- Map Placeholder -->
                    <!-- <div class="col-3 col-md-3 ms-1 mb-2 mt-2">
              <label>Map</label>
              <div class="map-placeholder">Map</div>
            </div> -->

                    <!-- Start Date Field -->
                    <div class="col-5 col-md-5 ms-1 mb-2 mt-2">
                        <label for="startDate">Start Date <span class="text-danger">*</span></label>
                        <div class="d-flex align-items-center">
                            <input class="form-control" type="date" id="startDate" formControlName="startDate" min="{{maxdate | date:'yyyy-MM-dd'}}" />
                            <span class="ms-2 btn btn-primary" style="width:20%;" (click)="getSlots()">Get Slots</span>
                        </div>
                        <div *ngIf="addressForm.get('startDate')?.invalid && addressForm.get('startDate')?.touched"
                            class="text-danger">
                            Start Date is required.
                        </div>
                    </div>

                </div>
            </div>
            <div>
                <div *ngIf="slots?.length>0">
                    <h4>Select Slot</h4>
                </div>
                <div class="d-flex flex-wrap">

                    <div *ngFor="let slot of slots" class="me-2">
                        <input type="radio" class="btn-check" name="options" [id]="'option' + slot?.id"
                            autocomplete="off" [value]="slot?.id">

                        <label class="btn btn-outline-primary" [for]="'option' + slot?.id"
                            style="width: 100%; margin: 10px; border-radius: 6px;">
                            {{ slot?.format_12_hrs?.start_time }}
                        </label>
                    </div>
                </div>
            </div>
            <div class="row ms-3">
                <div class="col-5 col-md-5 col-lg-5 ms-1 mb-2 mt-2">
                    <label for="address">Dignostic Test <span class="text-danger">*</span></label>
                    <ng-autocomplete style="border-radius: 10px;" [data]="servicesList" name="name"
                        [placeholder]="servicePlaceholder" [searchKeyword]="searchkeyword" notFoundText="Not found"
                        (inputChanged)='serviceSearch($event)' [itemTemplate]="itemTemplate"
                        (selected)='serachedList($event)' [notFoundTemplate]="notFoundTemplate">
                    </ng-autocomplete>
                    <ng-template #itemTemplate let-item>
                        <a [innerHTML]="item.name"></a>
                    </ng-template>
                    <ng-template #notFoundTemplate let-notFound>
                        <div [innerHTML]="notFound"></div>
                    </ng-template>
                    <!-- <div *ngIf="addressForm.get('address')?.invalid && addressForm.get('address')?.touched" class="text-danger">
                  Address is required.
                </div> -->
                </div>
                <div class="col-2 col-md-2 col-lg-2 ms-1 mb-2 mt-2">
                    <button class="btn btn-primary mt-4" (click)="addServiceToList()">Add Service</button>
                </div>

            </div>
            <div class="d-flex flex-wrap">
                <div class="card ms-3 " style="width: 25rem;" *ngFor="let service of addedServices">
                    <div class="card-header">{{service?.name}}</div>
                    <div class="card-body">
                        <div class="card-subtitle mb-2 text-muted">Descripton :</div>
                        <p class="card-text">{{service?.description}}</p>

                    </div>
                    <div class="card-footer">
                        <div class="row">
                            <div class="col-md-6 text-start">
                                <h6>Price :</h6>
                            </div>
                            <div class="col-md-6 text-end">
                                <h6>{{service?.package_center_prices?.package_price| currency:"INR"}}</h6>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6 text-start">
                                <h5>Offer Price :</h5>
                            </div>
                            <div class="col-md-6 text-end">
                                <h5>{{service?.package_center_prices?.offer_price| currency :"INR"}}</h5>
                            </div>
                        </div>
                        <div class="text-end">
                            <button type="button" class="btn btn-danger"
                                (click)="deleteSelectedService(service?.id)">Delete</button>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Form Submit Button -->
            <div class="ms-3 mt-4">
                <button type="submit" class="btn btn-primary" [disabled]="addressForm.invalid">Submit</button>
            </div>
        </div>
    </form>
</div>